<template>
    <div>
        <SimpleAccordion>
            <template v-slot:accordionTitle>
                <span class="me-3">Devis et factures</span>
                <button
                    v-if="$store.state.login.user.userData.accountType === 'workerAccount'"
                    class="btn btn-primary btn-sm"
                    @click.prevent="openModalCreateQuotation"
                >
                    Ajouter un devis
                </button>
                <button
                    v-if="$store.state.login.user.userData.accountType === 'workerAccount'"
                    class="ms-3 btn btn-primary btn-sm"
                    @click.prevent="openModalCreateInvoice"
                >
                    Charger une facture
                </button>
            </template>
            <template v-slot:accordionContent>
                <vue-good-table
                    :is-loading="listIsUpdating"
                    :columns="columns"
                    :rows="list"
                    :group-options="{
                        enabled: true,
                        rowKey: 'id',
                        collapsable: true
                    }"
                >
                    <template slot="loadingContent">
                        <span class="vgt-loading__content">Téléchargement...</span>
                    </template>
                    <template v-slot:table-header-row="props">
                        <div v-if="props.column.field === 'actions'" class="d-flex flex-column gap-2">
                            <button
                                class="btn btn-primary"
                                @click="
                                    downloadDocumentInParentRow(
                                        props.row.document.randomIdentifier,
                                        props.row.document.originalName,
                                    )
                                "
                            >
                                <font-awesome-icon icon="fa-solid fa-file-arrow-down" style="font-size: 1rem" />
                            </button>
                            <div class="btn-group" v-if="!props.row.acceptedAt && $store.getters['login/isAgency'] && props.row.type === 'Devis'">
                                <button
                                    type="button"
                                    class="btn btn-warning"
                                    @click="openModalRejectQuotation(props.row)"
                                >
                                    Refuser le devis
                                </button>
                            </div>
                            <div class="btn-group" v-if="!props.row.acceptedAt && $store.getters['login/isWorker'] && props.row.type === 'Devis'">
                                <button
                                    type="button"
                                    class="btn btn-warning dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Supprimer le devis
                                </button>
                                <ul class="dropdown-menu bg-transparent border-0">
                                    <li>
                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            @click="deleteQuotation(props.row)"
                                        >
                                            Confirmer
                                            <div
                                                v-if="deleteQuotationInProgress"
                                                class="uk-margin-left uk-inline"
                                                uk-spinner="ratio:0.5"
                                            ></div>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <button
                                v-if="!props.row.acceptedAt && $store.getters['login/isAgency'] && props.row.type === 'Devis'"
                                class="btn btn-success"
                                @click="acceptQuotation(props.row)"
                            >
                                Accepter le devis
                                <div
                                    v-if="acceptQuotationInProgress"
                                    class="uk-margin-left uk-inline"
                                    uk-spinner="ratio:0.5"
                                ></div>
                            </button>
                            <span v-if="props.row.acceptedAt && props.row.type === 'Devis'" style="color: #198754">
                                Devis validé le {{ props.row.acceptedAt | humanReadableDate }}</span
                            >
                            <div class="btn-group" v-if="!props.row.acceptedAt && $store.getters['login/isWorker'] && props.row.type === 'Facture'">
                                <button
                                    type="button"
                                    class="btn btn-warning dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Supprimer la facture
                                </button>
                                <ul class="dropdown-menu bg-transparent border-0">
                                    <li>
                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            @click="deleteInvoice(props.row)"
                                        >
                                            Confirmer
                                            <div
                                                v-if="deleteInvoiceInProgress"
                                                class="uk-margin-left uk-inline"
                                                uk-spinner="ratio:0.5"
                                            ></div>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="props.column.field === 'mainPurpose'">
                            <div class="m-2">
                                {{ props.formattedRow[props.column.field] }}
                            </div>
                            <SimpleAccordion v-if="hiddenFields.length > 0">
                                <template v-slot:accordionTitle>Plus d'infos</template>
                                <template v-slot:accordionContent>
                                    <div class="p-2">
                                        <div v-if="hiddenFields.includes('amountWoTax')" class="mb-3">
                                            <p>
                                                Montant HT : {{ props.formattedRow['amountWoTax'] }}<br />
                                                Montant TTC : {{ props.formattedRow['amountWithTax'] }}
                                            </p>
                                        </div>
                                        <div v-if="hiddenFields.includes('document.ownedBy')" class="mb-3">
                                            <p>Par : {{ props.formattedRow['document.ownedBy'] }}</p>
                                        </div>
                                        <div v-if="hiddenFields.includes('createdAt')" class="mb-3">
                                            <p>Le : {{ props.formattedRow['createdAt'] }}</p>
                                        </div>
                                    </div>
                                </template>
                            </SimpleAccordion>
                        </div>
                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>
                    <template v-slot:table-row="props">
                        <div v-if="props.column.field === 'actions'" class="d-flex flex-column gap-2">
                            <button
                                class="btn btn-primary"
                                @click="
                                    downloadDocumentInChildRow(
                                        props.row.document.randomIdentifier,
                                        props.row.document.originalName,
                                    )
                                "
                            >
                                <font-awesome-icon icon="fa-solid fa-file-arrow-down" style="font-size: 1rem" />
                            </button>
                            <div class="btn-group" v-if="!props.row.acceptedAt && $store.getters['login/isWorker'] && props.row.type === 'Facture'">
                                <button
                                    type="button"
                                    class="btn btn-warning dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Supprimer la facture
                                </button>
                                <ul class="dropdown-menu bg-transparent border-0">
                                    <li>
                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            @click="deleteInvoice(props.row)"
                                        >
                                            Confirmer
                                            <div
                                                v-if="deleteInvoiceInProgress"
                                                class="uk-margin-left uk-inline"
                                                uk-spinner="ratio:0.5"
                                            ></div>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="props.column.field === 'mainPurpose'">
                            <div class="m-2">
                                {{ props.formattedRow[props.column.field] }}
                            </div>
                            <SimpleAccordion v-if="hiddenFields.length > 0">
                                <template v-slot:accordionTitle>Plus d'infos</template>
                                <template v-slot:accordionContent>
                                    <div class="p-2">
                                        <div v-if="hiddenFields.includes('amountWoTax')" class="mb-3">
                                            <p>
                                                Montant HT : {{ props.formattedRow['amountWoTax'] }}<br />
                                                Montant TTC : {{ props.formattedRow['amountWithTax'] }}
                                            </p>
                                        </div>
                                        <div v-if="hiddenFields.includes('document.ownedBy')" class="mb-3">
                                            <p>Par : {{ props.formattedRow['document.ownedBy'] }}</p>
                                        </div>
                                        <div v-if="hiddenFields.includes('createdAt')" class="mb-3">
                                            <p>Le : {{ props.formattedRow['createdAt'] }}</p>
                                        </div>
                                    </div>
                                </template>
                            </SimpleAccordion>
                        </div>
                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>
                </vue-good-table>
            </template>
        </SimpleAccordion>
        <blank-modal ref="createQuotationModal" :modal-id="randomId">
            <template #modalHeader>Ajouter un devis</template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <FileUpload
                        choix-file-text="Selectionnez le document"
                        :is-multiple="false"
                        :files-uploaded.sync="createQuotationData.documents"
                    />
                    <div class="uk-margin">
                        <label class="uk-form-label">Objectif principal</label>
                        <div class="uk-form-controls">
                            <input class="uk-input" type="text" required v-model="createQuotationData.form.mainPurpose" />
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Montant TTC</label>
                        <div class="uk-form-controls">
                            <input
                                step=".01"
                                class="uk-input"
                                type="number"
                                required
                                v-model="createQuotationData.form.amountWithTax"
                            />
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Montant HT</label>
                        <div class="uk-form-controls">
                            <input
                                step=".01"
                                class="uk-input"
                                type="number"
                                required
                                v-model="createQuotationData.form.amountWoTax"
                            />
                        </div>
                    </div>
                </fieldset>
            </template>
            <template v-slot:modalFooterExtra>
                <button class="btn btn-primary" @click="confirmCreateQuotation">
                    Ajouter
                    <div v-if="createQuotationInProgress" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
        <blank-modal ref="rejectQuotationModal" :modal-id="randomId">
            <template #modalHeader>
                <legend class="uk-legend">Réfuser un devis</legend>
            </template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <multiselect
                                v-model="rejectQuotationData.selectedReason"
                                :options="rejectQuotationData.rejectReason.dropdownList"
                                :allow-empty="false"
                                selectedLabel=""
                                selectLabel="Cliquez pour sélectionner"
                                deselectLabel="Cliquez pour désélectionner"
                                track-by="value"
                                label="label"
                                placeholder="Liste des raison de refus de devis"
                            />
                        </div>
                    </div>
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <textarea
                                class="form-control mb-3"
                                v-model="rejectQuotationData.rejectReason.comment"
                                rows="5"
                                placeholder="Commentaire"
                            ></textarea>
                        </div>
                    </div>
                </fieldset>
            </template>
            <template v-slot:modalFooterExtra>
                <button class="btn btn-primary" @click="confirmRejectQuotation">
                    Confirmer
                    <div v-if="rejectQuotationInProgress" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
        <blank-modal ref="createInvoiceModal" :modal-id="randomId">
            <template #modalHeader>Ajouter une facture</template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <FileUpload
                        choix-file-text="Selectionnez le document"
                        :is-multiple="false"
                        :files-uploaded.sync="createInvoiceData.documents"
                    />
                    <div class="uk-margin">
                        <label class="uk-form-label">Montant pré-validé ou devis rattaché</label>
                        <multiselect
                            v-model="createInvoiceData.form.quotationAndPreValidatedAmount"
                            :options="createInvoiceDataOptions"
                            :allow-empty="false"
                            selectedLabel=""
                            selectLabel="Cliquez pour sélectionner"
                            deselectLabel="Cliquez pour désélectionner"
                            track-by="value"
                            label="value"
                            placeholder="Liste des montants pré-validé ou devis"
                        >
                            <template v-slot:noOptions>Aucun Montant pré-validé ou devis disponible</template>
                        </multiselect>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Objectif principal</label>
                        <div class="uk-form-controls">
                            <input class="uk-input" type="text" required v-model="createInvoiceData.form.mainPurpose" />
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Montant TTC</label>
                        <div class="uk-form-controls">
                            <input
                                step=".01"
                                class="uk-input"
                                type="number"
                                required
                                v-model="createInvoiceData.form.amountWithTax"
                            />
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Montant HT</label>
                        <div class="uk-form-controls">
                            <input
                                step=".01"
                                class="uk-input"
                                type="number"
                                required
                                v-model="createInvoiceData.form.amountWoTax"
                            />
                        </div>
                    </div>
                </fieldset>
            </template>
            <template v-slot:modalFooterExtra>
                <button class="btn btn-primary" @click="confirmCreateInvoice">
                    Ajouter
                    <div v-if="createInvoiceInProgress" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import SimpleAccordion from '@/components/SimpleAccordion'
import Vue from 'vue'
import {mapActions, mapState} from "vuex";
import backendApi from "@/backend/api";
import store from "@/store";
import random from "@/helpers/random";
import BlankModal from "@/components/BlankModal.vue";
import {rejectQuotationList} from "@/enum/rejectQuotationReasons";
import FileUpload from "@/components/FileUpload.vue";

export default {
    name: 'QuotationsAndInvoicesList',
    components: {
        FileUpload,
        BlankModal,
        SimpleAccordion,
    },
    data: () => ({
        randomId: random.htmlId(),
        createInvoiceData: {
            documents: [],
            form: {
                quotationAndPreValidatedAmount: [],
                mainPurpose: '',
                amountWithTax: 0,
                amountWoTax: 0,
                isActive: true,
                requireTaxReduceForm: false,
                siteUrl: window.location.protocol + '//' + window.location.host,
            },
        },
        createQuotationData: {
            documents: [],
            form: {
                mainPurpose: '',
                amountWithTax: 0,
                amountWoTax: 0,
                isActive: true,
                requireTaxReduceForm: false,
                dataUrl: window.location.protocol + '//' + window.location.host,
            },
        },
        rejectQuotationData: {
            selectedQuotation: [],
            selectedReason: null,
            rejectReason: {
                dropdownList: rejectQuotationList,
                comment: '',
            },
        },
    }),
    computed: {
        ...mapState('quotationsAndInvoices', [
            'list',
            'listIsUpdating',
            'createQuotationInProgress',
            'deleteQuotationInProgress',
            'acceptQuotationInProgress',
            'rejectQuotationInProgress',
            'createInvoiceInProgress',
            'deleteInvoiceInProgress',
        ]),
        columns: function() {
            return [
                {
                    label: 'Devis / Facture',
                    field: 'type',
                },
                {
                    label: 'Objet',
                    field: 'mainPurpose',
                    hidden: this.shouldBeHidden('xs'),
                },
                {
                    label: 'Montant HT',
                    field: 'amountWoTax',
                    hidden: this.shouldBeHidden('sm'),
                    formatFn: Vue.filter('amountFormat'),
                },
                {
                    label: 'Montant TTC',
                    field: 'amountWithTax',
                    hidden: this.shouldBeHidden('sm'),
                    formatFn: Vue.filter('amountFormat'),
                },
                {
                    label: 'Envoyé par',
                    field: 'document.ownedBy',
                    hidden: this.shouldBeHidden('xl'),
                },
                {
                    label: 'Le',
                    field: 'createdAt',
                    hidden: this.shouldBeHidden('xl'),
                    formatFn: Vue.filter('humanReadableDate'),
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    width: '10%',
                },
            ]
        },
        createInvoiceDataOptions() {
            const filteredList = this.list.filter(item => item.type === 'Devis')

            return filteredList.map((quotation) => {
                return {
                    value: quotation.mainPurpose,
                    id: quotation.id,
                }
            })
        },
        hiddenFields: function () {
            let hiddenFields = []
            this.columns.forEach((column) => {
                if (column.hidden) {
                    hiddenFields.push(column.field)
                }
            })

            return hiddenFields
        },
    },
    methods: {
        ...mapActions('notifications', ['addNotification']),
        ...mapActions('quotationsAndInvoices', [
            'createQuotation',
            'acceptQuotation',
            'rejectQuotation',
            'deleteQuotation',
            'createInvoice',
            'deleteInvoice',
        ]),
        shouldBeHidden(at) {
            const bp = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'big', 'giga']
            if (!bp.includes(at)) {
                return false
            }

            return !(bp.indexOf(at) <= bp.indexOf(this.$mq))
        },
        openModalCreateQuotation() {
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.createQuotationModal.$el).show()
        },
        confirmCreateQuotation() {
            if (!this.createQuotationData.documents[0]) {
                this.addNotification({ status: 'warning', message: 'Veuillez sélectionner le document' })
                return
            }

            if (!this.createQuotationData.form.mainPurpose) {
                this.addNotification({ status: 'warning', message: 'Veuillez saisir un objectif principal' })
                return
            }

            const payload = {
                ...this.createQuotationData.form,
                document: this.createQuotationData.documents[0],
            }

            this.createQuotation(payload).then(() => {
                this.resetCreateQuotationForm()

                this.$bootstrap.Modal.getOrCreateInstance(this.$refs.createQuotationModal.$el).hide()
            })
        },
        resetCreateQuotationForm() {
            this.createQuotationData.documents = []
            this.createQuotationData.form.mainPurpose = ''
            this.createQuotationData.form.amountWithTax = 0
            this.createQuotationData.form.amountWoTax = 0
            this.createQuotationData.form.isActive = true
            this.createQuotationData.form.requireTaxReduceForm = false
            this.createQuotationData.form.dataUrl = window.location.protocol + '//' + window.location.host
        },
        openModalCreateInvoice() {
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.createInvoiceModal.$el).show()
        },
        downloadDocumentInParentRow(identifier, originalName) {
            backendApi.downloadDocument(store.state.login.user.token, identifier, originalName)
        },
        downloadDocumentInChildRow(identifier, originalName) {
            backendApi.downloadDocument(store.state.login.user.token, identifier, originalName)
        },
        openModalRejectQuotation(data) {
            this.rejectQuotationData.selectedQuotation = data
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.rejectQuotationModal.$el).show()
        },
        confirmRejectQuotation() {
            if (!this.rejectQuotationData.selectedReason) {
                this.addNotification({
                    status: 'warning',
                    message: 'Veuillez sélectionner la raison de réfus du devis',
                })
                return
            }

            if (this.rejectQuotationData.rejectReason.comment === '') {
                this.addNotification({ status: 'warning', message: 'Veuillez ajouter un commentaire' })
                return
            }

            const payload = {
                ...this.rejectQuotationData.selectedQuotation,
                reason: this.rejectQuotationData.selectedReason.value,
                comment: this.rejectQuotationData.rejectReason.comment,
            }

            this.rejectQuotation(payload).then(() => {
                this.resetRejectQuotationForm()
                this.$bootstrap.Modal.getOrCreateInstance(this.$refs.rejectQuotationModal.$el).hide()
            })
        },
        resetRejectQuotationForm() {
            this.rejectQuotationData.selectedReason = null
            this.rejectQuotationData.rejectReason.comment = ''
        },
        confirmCreateInvoice() {
            if (!this.createInvoiceData.documents[0]) {
                this.addNotification({ status: 'warning', message: 'Veuillez sélectionner le document' })
                return
            }

            if (!this.createInvoiceData.form.mainPurpose) {
                this.addNotification({ status: 'warning', message: 'Veuillez saisir un objectif principal' })
                return
            }
            const quotationId = this.createInvoiceData.form.quotationAndPreValidatedAmount ? this.createInvoiceData.form.quotationAndPreValidatedAmount.id : null

            const payload = {
                ...this.createInvoiceData.form,
                document: this.createInvoiceData.documents[0],
                quotationId: quotationId,
            }

            this.createInvoice(payload).then(() => {
                this.resetCreateInvoiceForm()

                this.$bootstrap.Modal.getOrCreateInstance(this.$refs.createInvoiceModal.$el).hide()
            })
        },
        resetCreateInvoiceForm() {
            this.createInvoiceData.documents = []
            this.createInvoiceData.form.siteUrl = window.location.protocol + '//' + window.location.hostname
            this.createInvoiceData.form.mainPurpose = ''
            this.createInvoiceData.form.quotationAndPreValidatedAmount = []
            this.createInvoiceData.form.amountWithTax = 0
            this.createInvoiceData.form.amountWoTax = 0
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
