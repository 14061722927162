import backendApi from '../../backend/api'

export default {
    namespaced: true,
    state: () => ({
        quotationList: [],
        quotationListIsUpdating: false,
        addQuotationInProgress: false,
        acceptQuotationInProgress: false,
        rejectQuotationInProgress: false,
        deleteQuotationInProgress: false,
    }),
    mutations: {
        UPDATE_QUOTATION(store, payload) {
            store.quotationList = payload
        },
    },
    actions: {
        fetchQuotationsFromTicket(context) {
            return backendApi
                .getTicket(context.rootState.login.user.token, context.rootState.tickets.currentTicket.id)
                .then((data) => {
                    context.commit('UPDATE_QUOTATION', data.quotations)
                })
        },
        fetchQuotationsFromMissionOrder(context) {
            return backendApi
                .getMissionOrder(
                    context.rootState.login.user.token,
                    context.rootState.missionOrders.currentMissionOrder.id,
                )
                .then((data) => {
                    context.commit('UPDATE_QUOTATION', data.quotations)
                })
        },
        addQuotation(context, payload) {
            const payloadData = {
                payload: payload,
                ticket: context.rootState.tickets.currentTicket,
                missionOrder: context.rootState.missionOrders.currentMissionOrder,
            }
            context.state.quotationListIsUpdating = true
            context.state.addQuotationInProgress = true
            return backendApi
                .addQuotation(context.rootState.login.user.token, payloadData)
                .then((data) => {
                    context.dispatch(
                        'notifications/addNotification',
                        {
                            status: 'success',
                            message: 'Devis ajouté avec success',
                        },
                        { root: true },
                    )
                    if (payloadData.ticket.id !== null) {
                        context.dispatch('fetchQuotationsFromTicket', data.quotations)
                    }
                    if (payloadData.missionOrder.id !== null) {
                        context.dispatch('fetchQuotationsFromMissionOrder', data.quotations)
                    }
                })
                .finally(() => {
                    context.state.quotationListIsUpdating = false
                    context.state.addQuotationInProgress = false
                })
        },
        acceptQuotation(context, quotation) {
            const payloadData = {
                ...quotation,
                dataUrl: window.location.protocol + '//' + window.location.host,
            }
            const ticket = context.rootState.tickets.currentTicket
            const missionOrder = context.rootState.missionOrders.currentMissionOrder
            context.state.quotationListIsUpdating = true
            context.state.acceptQuotationInProgress = true
            return backendApi
                .acceptQuotation(context.rootState.login.user.token, payloadData)
                .then(() => {
                    context.dispatch(
                        'notifications/addNotification',
                        {
                            status: 'success',
                            message: 'Devis validé',
                        },
                        { root: true },
                    )
                    if (ticket.id !== null) {
                        context.dispatch('fetchQuotationsFromTicket', ticket.quotations)
                    }
                    if (missionOrder.id !== null) {
                        context.dispatch('fetchQuotationsFromMissionOrder', missionOrder.quotations)
                    }
                })
                .finally(() => {
                    context.state.quotationListIsUpdating = false
                    context.state.acceptQuotationInProgress = false
                })
        },
        rejectQuotation(context, payload) {
            const ticket = context.rootState.tickets.currentTicket
            const missionOrder = context.rootState.missionOrders.currentMissionOrder
            context.state.quotationListIsUpdating = true
            context.state.rejectQuotationInProgress = true
            return backendApi
                .rejectQuotation(context.rootState.login.user.token, payload)
                .then(() => {
                    context.dispatch(
                        'notifications/addNotification',
                        {
                            status: 'success',
                            message: 'Devis rejeté',
                        },
                        { root: true },
                    )
                    if (ticket.id !== null) {
                        context.dispatch('fetchQuotationsFromTicket', ticket.quotations)
                    }
                    if (missionOrder.id !== null) {
                        context.dispatch('fetchQuotationsFromMissionOrder', missionOrder.quotations)
                    }
                })
                .finally(() => {
                    context.state.quotationListIsUpdating = false
                    context.state.rejectQuotationInProgress = false
                })
        },
        deleteQuotation(context, quotation) {
            const ticket = context.rootState.tickets.currentTicket
            const missionOrder = context.rootState.missionOrders.currentMissionOrder
            context.state.quotationListIsUpdating = true
            context.state.deleteQuotationInProgress = true
            return backendApi
                .deleteQuotation(context.rootState.login.user.token, quotation.id)
                .then(() => {
                    context.dispatch(
                        'notifications/addNotification',
                        {
                            status: 'success',
                            message: 'Devis supprimé',
                        },
                        { root: true },
                    )
                    if (ticket.id !== null) {
                        context.dispatch('fetchQuotationsFromTicket', ticket.quotations)
                    }
                    if (missionOrder.id !== null) {
                        context.dispatch('fetchQuotationsFromMissionOrder', missionOrder.quotations)
                    }
                })
                .finally(() => {
                    context.state.quotationListIsUpdating = false
                    context.state.deleteQuotationInProgress = false
                })
        },
    },
    getters: {},
}
