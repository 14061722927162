<template>
    <SimpleAccordion>
        <template v-slot:accordionTitle>Entreprise de sous-traitants et prestataires notifiés du ticket</template>
        <template v-slot:accordionContent>
            <div id="output-selected-row"></div>
            <vue-good-table :columns="columns" :rows="workerCompanies" :pagination-options="pagination">
                <template v-slot:table-row="props">
                    <div v-if="props.column.field === 'buildingTrades'" class="d-flex gap-3">
                        <span
                            v-for="trade in props.formattedRow[props.column.field]"
                            :key="'trade-' + trade.id"
                            class="badge bg-primary"
                            >{{ trade.value }}</span
                        >
                    </div>
                </template>
            </vue-good-table>
        </template>
    </SimpleAccordion>
</template>

<script>
import SimpleAccordion from '@/components/SimpleAccordion'

export default {
    name: 'InformedWorkersList',
    components: { SimpleAccordion },
    emits: ['remove:worker'],
    data: () => ({
        columns: [
            {
                label: 'Corps de métiers connus',
                field: 'buildingTrades',
            },
            {
                label: 'Compagnie',
                field: 'company.name',
            },
            {
                label: 'SIREN',
                field: 'company.registrationNumber',
            },
        ],
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'both',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
            // infoFn: (params) => `Page ${params.currentPage}`,
        },
    }),
    props: {
        workerCompanies: {
            required: false,
            default: () => [],
        },
        ifSelectionChanged: {
            required: false,
            type: Function,
            default: () => () => {},
        },
    },
}
</script>

<style scoped>
#output-selected-row {
    margin: 10px 0 10px 0;
}
</style>
