export default {
    namespaced: true,
    state: () => ({
        notifications: [],
    }),
    mutations: {
        ADD_NOTIFICATION(store, payload) {
            store.notifications.push(payload)
        },
        SET_NOTIFICATIONS(store, payload) {
            store.notifications = payload
        },
    },
    actions: {
        addNotification(context, payload) {
            context.commit('ADD_NOTIFICATION', payload)
        },
        setNotifications(context, payload) {
            context.commit('SET_NOTIFICATIONS', payload)
        },
    },
    getters: {},
}
