<template>
    <div class="modal fade" :id="this.modalId" tabindex="-1" aria-hidden="true">
        <div
            class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
            :class="{ 'modal-fullscreen': isFullScreen }"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <slot name="modalHeader"></slot>
                </div>
                <div class="modal-body">
                    <slot name="modalBody"></slot>
                </div>
                <div class="modal-footer">
                    <slot name="modalFooterExtra"></slot>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlankModal',
    data: () => ({}),
    props: {
        isFullScreen: {
            required: false,
            default: false,
        },
        modalId: {
            required: true,
            type: String,
        },
    },
}
</script>

<style scoped></style>
