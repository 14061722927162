import { render, staticRenderFns } from "./MissionOrdersList.vue?vue&type=template&id=23a31fca&scoped=true&"
import script from "./MissionOrdersList.vue?vue&type=script&lang=js&"
export * from "./MissionOrdersList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23a31fca",
  null
  
)

export default component.exports