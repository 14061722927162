<template>
    <div class="uk-padding-small">
        <div
            class="uk-card uk-card-default uk-background-muted uk-card-body uk-border-rounded uk-padding-small"
            style="border: #999999 solid 1px"
        >
            <h1 class="uk-card-title">Propriété</h1>
            <div class="uk-margin">
                <label class="uk-form-label">Nom / Référence</label>
                <div class="uk-form-controls">
                    <input
                        class="uk-input"
                        type="text"
                        placeholder="Exemple: Propriété de Mme Ballan 6ème étage"
                        v-model="property.name"
                    />
                </div>
            </div>
            <div class="uk-margin">
                <label class="uk-form-label">Adresse</label>
                <div class="uk-form-controls">
                    <input
                        ref="address"
                        class="uk-input"
                        type="text"
                        placeholder="Exemple: Propriétaire Mme Ballan 6ème étage"
                        v-model="property.address.formattedAddress"
                    />
                </div>
            </div>
            <ExtraInfos
                :extra-infos.sync="property.extraInfos"
                v-on:setExtraInfosAsEmptyObject="setExtraInfosAsEmptyObject"
            />
            <div
                class="uk-card uk-card-default uk-border-rounded uk-padding-small uk-margin-bottom"
                style="border: #999999 solid 1px"
            >
                <h4>
                    <span class="uk-margin-small-right">Contacts liés à la propriété</span>
                    <vk-button
                        type="primary"
                        size="small"
                        class="uk-border-rounded"
                        @click="property.contacts.push(defaultProps.contact())"
                        >Ajouter</vk-button
                    >
                </h4>
                <div class="uk-card-body uk-padding-small">
                    <Contact
                        v-for="(contact, index) in property.contacts"
                        :key="'contact-' + index"
                        :contact.sync="contact"
                        :iteration-key="index"
                        v-on:deleteContact="deleteContact"
                    />
                </div>
            </div>
        </div>
        <!--
        TODO sub property is not really needed, see later for implementation
    <div class="uk-margin">
          <label class="uk-form-label">Peut contenir des sous propriétés</label>
          <div class="uk-form-controls">
            <select class="uk-select" v-model="property.canContainProperty">
              <option :value="true">Oui</option>
              <option :value="false">Non</option>
            </select>
          </div>
        </div>
        <div class="uk-margin" v-if="property.canContainProperty">
          <table class="uk-table uk-table-striped uk-table-small">
            <caption><h5>Sous propriétés</h5></caption>
            <thead>
            <tr>
              <th>Table Heading</th>
              <th>Table Heading</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Table Data</td>
              <td>Table Data</td>
            </tr>
            <tr>
              <td>Table Data</td>
              <td>Table Data</td>
            </tr>
            </tbody>
          </table>
          <hr>
        </div>
        -->
    </div>
</template>

<script>
import Contact from '@/components/Contact'
import ExtraInfos from '@/components/ExtraInfos'
export default {
    name: 'Property',
    components: { ExtraInfos, Contact },
    data: () => ({
        defaultProps: {
            contact: Contact.props.contact.default,
        },
    }),
    props: {
        property: {
            required: false,
            default: () => ({
                name: '',
                canContainProperty: false,
                address: {
                    formattedAddress: '',
                },
                contacts: [],
                extraInfos: {},
                subProperties: [],
            }),
        },
    },
    methods: {
        deleteContact(key) {
            if (key !== null) {
                this.$delete(this.property.contacts, key)
            }
        },
        setExtraInfosAsEmptyObject() {
            this.property.extraInfos = {}
        },
    },
    mounted() {
        const addressInput = this.$refs.address
        // eslint-disable-next-line no-undef
        const addressSelected = new google.maps.places.Autocomplete(addressInput, {
            componentRestrictions: {
                country: 'FR',
            },
            types: ['geocode'],
        })

        addressSelected.addListener('place_changed', () => {
            const place = addressSelected.getPlace()
            this.property.address.formattedAddress = place.formatted_address
        })
    },
}
</script>

<style scoped></style>
