<template>
    <div class="uk-margin">
        <label class="uk-form-label">Numéro</label>
        <div class="uk-form-controls uk-grid-collapse uk-child-width-expand@s" uk-grid>
            <input
                class="uk-input uk-padding-small uk-padding-remove-vertical"
                type="text"
                placeholder="Exemple: 06.01.02.03.04"
                v-model="phoneNumber.value"
            />
            <vk-button
                v-if="iterationKey !== null"
                type="danger"
                size="small"
                class="uk-width-auto uk-padding uk-padding-remove-vertical"
                @click="$emit('deletePhoneNumber', iterationKey)"
                >Supprimer</vk-button
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'PhoneNumber',
    props: {
        phoneNumber: {
            required: false,
            default: () => ({
                value: '',
            }),
        },
        iterationKey: {
            required: false,
            default: () => null,
        },
    },
}
</script>

<style scoped></style>
