import backendApi from '../../backend/api'

export default {
    namespaced: true,
    state: () => ({
        list: [],
        listIsUpdating: false,
    }),
    mutations: {
        UPDATE_USERS(store, payload) {
            store.list = payload
        },
    },
    actions: {
        updateUsersList(context) {
            context.state.listIsUpdating = true
            return backendApi
                .getUsersList(context.rootState.login.user.token)
                .then((data) => {
                    context.commit('UPDATE_USERS', data)
                })
                .catch(backendApi.notificationOnError)
                .catch(() => {
                    context.commit('UPDATE_USERS', [])
                })
                .finally(() => {
                    context.state.listIsUpdating = false
                })
        },
    },
    getters: {
        getUserFromList: (state) => (id) => {
            return state.list.find((user) => user.id == id)
        },
    },
}
