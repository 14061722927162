import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { Mediastore } from '@agorapulse/capacitor-mediastore'
import random from '@/helpers/random'

class download {
    async downloadBlob(blob, filename) {
        console.log(blob, filename)
        if (Capacitor.isNativePlatform()) {
            const device = await Device.getInfo()
            console.log(device)
            const base64 = await this.blobToBase64(blob)
            console.log(base64)
            if (device.platform === 'android' && parseInt(device.osVersion, 10) >= 10) {
                console.log('android >= 10')
                return await this.downloadForAndroid10OrMore(base64, filename)
            }
            console.log('basic native download')
            return await this.downloadForNative(base64, filename)
        }

        console.log('web download')
        return this.createLinkFromBlobAndClickIt(blob, filename)
    }

    async downloadForAndroid10OrMore(base64, filename) {
        let tempFileWrite = await Filesystem.writeFile({
            directory: Directory.Library,
            path: `tmp/${random.htmlId()}`,
            recursive: true,
            data: base64,
        })

        console.log('tempFileWrite', tempFileWrite)

        let saveToDownloadResult = await Mediastore.saveToDownloads({
            filename: filename,
            path: tempFileWrite.uri,
        })

        console.log('saveToDownloadResult', saveToDownloadResult)

        await Filesystem.deleteFile({
            path: tempFileWrite.uri,
        })

        return saveToDownloadResult
    }

    async downloadForNative(base64, filename) {
        return await Filesystem.writeFile({
            directory: Directory.ExternalStorage,
            path: filename,
            recursive: true,
            data: base64,
        })
    }

    blobToBase64(blob) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result.replace(/^data:[^,]+,/, ''))
            reader.readAsDataURL(blob)
        })
    }

    createLinkFromBlobAndClickIt(blob, filename) {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        return { uri: 'user-system-defined' }
    }
}

export default new download()
