export const rejectQuotationEnum = {
    PRICE_TOO_HIGH: 'PRICE_TOO_HIGH',
    QUOTATION_NOT_MATCH_NEEDS: 'QUOTATION_NOT_MATCH_NEEDS',
    OTHER_QUOTATION_ALREADY_VALIDATED: 'OTHER_QUOTATION_ALREADY_VALIDATED',
    QUOTATION_LACKS_DETAILS: 'QUOTATION_LACKS_DETAILS',
    OTHER: 'OTHER',
}

export const rejectQuotationEnumTranslate = {
    PRICE_TOO_HIGH: 'Tarif trop élevé',
    QUOTATION_NOT_MATCH_NEEDS: 'Devis pas adapté au besoin',
    OTHER_QUOTATION_ALREADY_VALIDATED: 'J’ai déjà accepté un autre devis',
    QUOTATION_LACKS_DETAILS: 'Devis pas assez détaillé',
    OTHER: 'Autre',
}

export const rejectQuotationTranslator = (reason) => {
    if (!rejectQuotationEnumTranslate[reason]) {
        throw new Error('Invalid rejectQuotationEnum value')
    }

    return rejectQuotationEnumTranslate[reason]
}

export const rejectQuotationList = Object.keys(rejectQuotationEnum).map((key) => ({
    value: key,
    label: rejectQuotationTranslator(rejectQuotationEnum[key]),
}))
