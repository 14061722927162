var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"uk-nav-default uk-nav-parent-icon",attrs:{"uk-nav":""}},[_c('vk-nav-item-header',{staticStyle:{"color":"#999999"},attrs:{"title":"Tickets et ordres"}}),_c('li',{staticClass:"uk-parent"},[_c('a',{staticStyle:{"color":"white"},attrs:{"href":"#"}},[_c('vk-icon',{staticClass:"uk-margin-small-right",attrs:{"icon":"location"}}),_vm._v(" Tickets")],1),_c('ul',{staticClass:"uk-nav-sub"},[_c('vk-nav-item',{class:{ 'active-on-click': _vm.$route.name === 'tickets' },attrs:{"title":"Liste"},on:{"click":function($event){_vm.$router.push({ name: 'tickets' })
                    _vm.$emit('nav:close')}}}),_c('vk-nav-item',{class:{ 'active-on-click': _vm.$route.name === 'ticket_new' },attrs:{"title":"Créer"},on:{"click":function($event){_vm.$router.push({ name: 'ticket_new' })
                    _vm.$emit('nav:close')}}})],1)]),_c('li',{staticClass:"uk-parent"},[_c('a',{staticStyle:{"color":"white"},attrs:{"href":"#"}},[_c('vk-icon',{staticClass:"uk-margin-small-right",attrs:{"icon":"nut"}}),_vm._v(" Ordres de mission")],1),_c('ul',{staticClass:"uk-nav-sub"},[_c('vk-nav-item',{class:{ 'active-on-click': _vm.$route.name === 'mission_orders' },attrs:{"title":"Liste"},on:{"click":function($event){_vm.$router.push({ name: 'mission_orders' })
                    _vm.$emit('nav:close')}}}),_c('vk-nav-item',{class:{ 'active-on-click': _vm.$route.name === 'mission_order_new' },attrs:{"title":"Créer"},on:{"click":function($event){_vm.$router.push({ name: 'mission_order_new' })
                    _vm.$emit('nav:close')}}})],1)]),_c('vk-nav-item-divider'),_c('vk-nav-item-header',{staticStyle:{"color":"#999999"},attrs:{"title":"Gestion interne"}}),_c('li',{staticClass:"uk-parent"},[_c('a',{staticStyle:{"color":"white"},attrs:{"href":"#"}},[_c('vk-icon',{staticClass:"uk-margin-small-right",attrs:{"icon":"location"}}),_vm._v(" Propriétés")],1),_c('ul',{staticClass:"uk-nav-sub"},[_c('vk-nav-item',{class:{ 'active-on-click': _vm.$route.name === 'properties' },attrs:{"title":"Lister / Modifier"},on:{"click":function($event){_vm.$router.push({ name: 'properties' })
                    _vm.$emit('nav:close')}}}),_c('vk-nav-item',{class:{ 'active-on-click': _vm.$route.name === 'property_new' },attrs:{"title":"Créer"},on:{"click":function($event){_vm.$router.push({ name: 'property_new' })
                    _vm.$emit('nav:close')}}})],1)]),_c('li',{staticClass:"uk-parent"},[_c('a',{staticStyle:{"color":"white"},attrs:{"href":"#"}},[_c('font-awesome-icon',{staticClass:"uk-margin-small-right",attrs:{"icon":"fa-solid fa-screwdriver-wrench"}}),_vm._v(" Sous-traitants ou prestataires ")],1),_c('ul',{staticClass:"uk-nav-sub"},[_c('vk-nav-item',{class:{ 'active-on-click': _vm.$route.name === 'workers_list' },attrs:{"title":"Lister / Retirer"},on:{"click":function($event){_vm.$router.push({ name: 'workers_list' })
                    _vm.$emit('nav:close')}}}),_c('vk-nav-item',{class:{ 'active-on-click': _vm.$route.name === 'worker_add' },attrs:{"title":"Ajouter"},on:{"click":function($event){_vm.$router.push({ name: 'worker_add' })
                    _vm.$emit('nav:close')}}})],1)]),_c('ItemManageStatus',_vm._g({},_vm.$listeners)),(_vm.$store.state.login.user.userData.roles.includes('admin'))?_c('ItemManageUsers',_vm._g({},_vm.$listeners)):_vm._e(),_c('li',{staticClass:"uk-parent",staticStyle:{"display":"none"}},[_c('a',{staticStyle:{"color":"white"},attrs:{"href":"#"}},[_c('vk-icon',{staticClass:"uk-margin-small-right",attrs:{"icon":"play-circle"}}),_vm._v(" Utiliser l'application")],1),_c('ul',{staticClass:"uk-nav-sub"},[_c('vk-nav-item',{class:{ 'active-on-click': _vm.$route.name === 'video-formations' },attrs:{"title":"Lister"},on:{"click":function($event){_vm.$router.push({ name: 'video-formations' })
                    _vm.$emit('nav:close')}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }