<template>
    <div class="accordion" id="objectAccordion">
        <div class="accordion-item">
            <h2 class="accordion-header" id="objectTitle">
                <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#object"
                    aria-expanded="false"
                >
                    {{ title }}
                </button>
            </h2>
            <div
                id="object"
                class="accordion-collapse collapse"
                aria-labelledby="objectTitle"
                data-bs-parent="#objectAccordion"
            >
                <p class="small m-3" style="white-space: pre-line">{{ object }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ObjectAccordion',
    props: {
        object: {
            required: false,
            default: () => '',
        },
        title: {
            required: false,
            default: () => 'Objet du ticket',
        },
    },
}
</script>

<style scoped></style>
