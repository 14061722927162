import backendApi from '../../backend/api'
import store from '@/store'
import Vue from 'vue'
import MissionOrder from '@/components/MissionOrder'
import router from '../../router'

export default {
    namespaced: true,
    state: () => ({
        currentMissionOrderUpdateIsPending: false,
        currentMissionOrderLastError: false,
        currentMissionOrder: MissionOrder.props.missionOrder.default(),
        updateMainChatTicker: null,
        intervalMainChatTicker: 10000,
        updateDocumentsTicker: null,
        intervalDocumentsTicker: 20000,
        recordPending: false
    }),
    mutations: {
        UPDATE_CURRENT_MISSION_ORDER(store, payload) {
            store.currentMissionOrder = payload
            console.log(store.currentMissionOrder)
        },
        UPDATE_MAIN_CHAT(store, payload) {
            store.currentMissionOrder.mainChat = payload
        },
        UPDATE_PRIVATE_CHAT(store, { privateChatId, payload }) {
            for (let i = 0; i < store.currentMissionOrder.privateChats.length; i++) {
                if (store.currentMissionOrder.privateChats[i].id === privateChatId) {
                    Vue.set(store.currentMissionOrder.privateChats, i, payload)
                    return
                }
            }
        },
        UPDATE_DOCUMENTS(store, payload) {
            store.currentMissionOrder.documents = payload
        },
    },
    actions: {
        setCurrentMissionOrder(context, id) {
            context.state.currentMissionOrderUpdateIsPending = true
            return backendApi
                .getMissionOrder(context.rootState.login.user.token, id)
                .then((data) => {
                    context.commit('UPDATE_CURRENT_MISSION_ORDER', data)
                })
                .finally(() => {
                    context.state.currentMissionOrderUpdateIsPending = false
                })
        },
        updateMainChat(context) {
            let missionOrderId = context.state.currentMissionOrder.id
            if (!missionOrderId) {
                return Promise.resolve()
            }
            return backendApi.getMissionOrderMainChat(store.state.login.user.token, missionOrderId).then((data) => {
                context.commit('UPDATE_MAIN_CHAT', data)
            })
        },
        updateMainChatTicker(context) {
            if (!context.state.updateMainChatTicker && !!context.state.currentMissionOrder.id) {
                context.state.updateMainChatTicker = setInterval(
                    () => context.dispatch('updateMainChat'),
                    context.state.intervalMainChatTicker,
                )
            }
        },
        clearMainChatTicker(context) {
            if (context.state.updateMainChatTicker) {
                clearInterval(context.state.updateMainChatTicker)
                context.state.updateMainChatTicker = null
            }
        },
        updatePrivateChat(context, privateChatId) {
            let missionOrderId = context.state.currentMissionOrder.id
            if (!missionOrderId) {
                return Promise.resolve()
            }
            return backendApi
                .getMissionOrderPrivateChat(store.state.login.user.token, missionOrderId, privateChatId)
                .then((payload) => {
                    context.commit('UPDATE_PRIVATE_CHAT', { privateChatId, payload })
                })
        },
        updateDocuments(context) {
            let missionOrderId = context.state.currentMissionOrder.id
            if (!missionOrderId) {
                return Promise.resolve()
            }
            return backendApi.getMissionOrder(context.rootState.login.user.token, missionOrderId).then((data) => {
                context.commit('UPDATE_DOCUMENTS', data.documents)
            })
        },
        updateDocumentsTicker(context) {
            if (!context.state.updateDocumentsTicker && !!context.state.currentMissionOrder.id) {
                context.state.updateDocumentsTicker = setInterval(
                    () => context.dispatch('updateDocuments'),
                    context.state.intervalDocumentsTicker,
                )
            }
        },
        clearDocumentsTicker(context) {
            if (context.state.updateDocumentsTicker) {
                clearInterval(context.state.updateDocumentsTicker)
                context.state.updateDocumentsTicker = null
            }
        },
        reset(context) {
            context.state.currentMissionOrder = MissionOrder.props.missionOrder.default()
        },
        createMissionOrder(context, missionOrder) {
            context.state.recordPending = true
            return backendApi
                .createMissionOrder(context.rootState.login.user.token, missionOrder)
                .then((data) => {
                    context.dispatch(
                        'notifications/addNotification',
                        {
                            status: 'success',
                            message: 'Ordre de mission créé',
                        },
                        { root: true },
                    )
                    router.push({ name: 'mission_order_view', params: { missionOrderId: data.missionOrderId } })
                })
                .finally(() => {
                    context.state.recordPending = false
                })
        }
    },
    getters: {},
}
