<template>
    <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
        <vk-nav-item-header title="Tickets et ordres" style="color: #999999" />
        <li>
            <a
                href="#"
                @click.prevent="
                    $router.push({ name: 'tickets' })
                    $emit('nav:close')
                "
                :class="{ 'active-on-click': $route.name === 'tickets' }"
                style="color: white"
                ><vk-icon icon="location" class="uk-margin-small-right" />Tickets</a
            >
        </li>
        <li>
            <a
                href="#"
                @click.prevent="
                    $router.push({ name: 'mission_orders' })
                    $emit('nav:close')
                "
                :class="{ 'active-on-click': $route.name === 'mission_orders' }"
                style="color: white"
                ><vk-icon icon="nut" class="uk-margin-small-right" />Ordres de mission</a
            >
        </li>
        <vk-nav-item-divider />
        <vk-nav-item-header title="Gestion interne" style="color: #999999" />
        <ItemManageStatus v-on="$listeners" />
        <ItemManageUsers v-on="$listeners" v-if="$store.state.login.user.userData.roles.includes('admin')" />
    </ul>
</template>

<script>
import ItemManageUsers from '@/components/menuItems/ItemManageUsers'
import ItemManageStatus from '@/components/menuItems/ItemManageStatus'
export default {
    name: 'ItemsWorkerAccount',
    components: { ItemManageStatus, ItemManageUsers },
}
</script>

<style scoped>
.uk-nav-default .uk-nav-sub a {
    color: #999 !important;
}
</style>
