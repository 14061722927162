<template>
    <div
        class="uk-card uk-card-default uk-card-hover uk-border-rounded uk-padding-small uk-margin-bottom"
        style="border: #999999 solid 1px"
    >
        <h4>Infos complémentaires</h4>
        <div
            v-for="(value, key) in extraInfos"
            :key="'extraInfo' + key"
            class="uk-grid-collapse uk-margin-small-bottom uk-child-width-expand@s"
            uk-grid
        >
            <div>
                <input class="uk-input" type="text" disabled :value="key" />
            </div>
            <div>
                <input class="uk-input" type="text" v-model="extraInfos[key]" />
            </div>
            <vk-button
                type="danger"
                size="small"
                class="uk-width-auto uk-padding uk-padding-remove-vertical"
                @click="deleteExtraInfo(key)"
                >Supprimer</vk-button
            >
        </div>
        <div class="uk-grid-collapse uk-margin-small-bottom uk-child-width-expand@s" uk-grid>
            <div>
                <input ref="newKey" class="uk-input" type="text" v-model="newKey" placeholder="Exemple: étage" />
            </div>
            <div>
                <input class="uk-input" type="text" v-model="newValue" placeholder="Exemple: 2ème" />
            </div>
            <vk-button
                type="primary"
                size="small"
                class="uk-width-auto uk-padding uk-padding-remove-vertical"
                @click="addExtraInfo()"
                >Ajouter</vk-button
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExtraInfos',
    emits: ['setExtraInfosAsEmptyObject'],
    data: () => ({
        newKey: '',
        newValue: '',
    }),
    props: {
        extraInfos: {
            required: false,
            default: () => ({}),
        },
    },
    methods: {
        deleteExtraInfo(key) {
            this.$delete(this.extraInfos, key)
        },
        async addExtraInfo() {
            if (this.extraInfos.length === 0) {
                await this.$emit('setExtraInfosAsEmptyObject')
            }
            if (this.newKey.length > 0) {
                this.extraInfos[this.newKey] = this.newValue
                this.$refs.newKey.classList.remove('uk-alert-danger')
                this.$refs.newKey.placeholder = 'Exemple: étage'
                this.newKey = ''
                this.newValue = ''
            } else {
                this.$refs.newKey.classList.add('uk-alert-danger')
                this.$refs.newKey.placeholder = 'Le champ ne peut pas être vide'
            }
        },
    },
}
</script>

<style scoped></style>
