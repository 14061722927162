<template>
    <div>
        <SimpleAccordion>
            <template v-slot:accordionTitle>
                <span class="me-3">Devis</span>
                <button
                    v-if="$store.state.login.user.userData.accountType === 'workerAccount'"
                    class="btn btn-primary btn-sm"
                    @click.prevent="openModalQuotationAdd"
                >
                    <font-awesome-icon icon="fa-solid fa-plus" />
                </button>
            </template>
            <template v-slot:accordionContent>
                <vue-good-table
                    :is-loading="quotationListIsUpdating"
                    styleClass="vgt-table striped bordered condensed"
                    :columns="columns"
                    :rows="quotationList"
                >
                    <template slot="loadingContent">
                        <span class="vgt-loading__content">Téléchargement...</span>
                    </template>
                    <template v-slot:table-row="props">
                        <div v-if="props.column.field === 'actions'" class="d-flex flex-column gap-2">
                            <button
                                class="btn btn-primary"
                                @click="
                                    downloadDocument(
                                        props.row.document.randomIdentifier,
                                        props.row.document.originalName,
                                    )
                                "
                            >
                                <font-awesome-icon icon="fa-solid fa-file-arrow-down" style="font-size: 1rem" />
                            </button>
                            <div class="btn-group" v-if="!props.row.acceptedAt && $store.getters['login/isAgency']">
                                <button
                                    type="button"
                                    class="btn btn-warning"
                                    @click="openModalQuotationReject(props.row)"
                                >
                                    Refuser le devis
                                </button>
                            </div>
                            <div class="btn-group" v-if="!props.row.acceptedAt && $store.getters['login/isWorker']">
                                <button
                                    type="button"
                                    class="btn btn-warning dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Supprimer le devis
                                </button>
                                <ul class="dropdown-menu bg-transparent border-0">
                                    <li>
                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            @click="deleteQuotation(props.row)"
                                        >
                                            Confirmer
                                            <div
                                                v-if="deleteQuotationInProgress"
                                                class="uk-margin-left uk-inline"
                                                uk-spinner="ratio:0.5"
                                            ></div>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <button
                                v-if="!props.row.acceptedAt && $store.getters['login/isAgency']"
                                class="btn btn-success"
                                @click="acceptQuotation(props.row)"
                            >
                                Accepter le devis
                                <div
                                    v-if="acceptQuotationInProgress"
                                    class="uk-margin-left uk-inline"
                                    uk-spinner="ratio:0.5"
                                ></div>
                            </button>
                            <span v-if="props.row.acceptedAt" style="color: #198754">
                                Devis validé le {{ props.row.acceptedAt | humanReadableDate }}</span
                            >
                        </div>
                        <div v-else-if="props.column.field === 'mainPurpose'">
                            <div class="m-2">
                                {{ props.formattedRow[props.column.field] }}
                            </div>
                            <SimpleAccordion v-if="hiddenFields.length > 0">
                                <template v-slot:accordionTitle>Plus d'infos</template>
                                <template v-slot:accordionContent>
                                    <div class="p-2">
                                        <div v-if="hiddenFields.includes('amountWoTax')" class="mb-3">
                                            <p>
                                                Montant HT : {{ props.formattedRow['amountWoTax'] }}<br />
                                                Montant TTC : {{ props.formattedRow['amountWithTax'] }}
                                            </p>
                                        </div>
                                        <div v-if="hiddenFields.includes('document.ownedBy')" class="mb-3">
                                            <p>Par : {{ props.formattedRow['document.ownedBy'] }}</p>
                                        </div>
                                        <div v-if="hiddenFields.includes('createdAt')" class="mb-3">
                                            <p>Le : {{ props.formattedRow['createdAt'] }}</p>
                                        </div>
                                    </div>
                                </template>
                            </SimpleAccordion>
                        </div>
                        <div v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </div>
                    </template>
                </vue-good-table>
            </template>
        </SimpleAccordion>
        <BlankModal ref="quotationModal" :modal-id="quotationModalId">
            <template #modalHeader>Ajouter un devis</template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <FileUpload
                        choix-file-text="Selectionnez le document"
                        :is-multiple="false"
                        :files-uploaded.sync="documents"
                    />
                    <div class="uk-margin">
                        <label class="uk-form-label">Objectif principal</label>
                        <div class="uk-form-controls">
                            <input class="uk-input" type="text" required v-model="addQuotationData.mainPurpose" />
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Montant TTC</label>
                        <div class="uk-form-controls">
                            <input
                                step=".01"
                                class="uk-input"
                                type="number"
                                required
                                v-model="addQuotationData.amountWithTax"
                            />
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Montant HT</label>
                        <div class="uk-form-controls">
                            <input
                                step=".01"
                                class="uk-input"
                                type="number"
                                required
                                v-model="addQuotationData.amountWoTax"
                            />
                        </div>
                    </div>
                    <label class="d-block" v-if="true === false">
                        <!-- temporary disable -->
                        <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="addQuotationData.requireTaxReduceForm"
                        />
                        Formulaire de réduction d'impôt
                    </label>
                </fieldset>
            </template>
            <template v-slot:modalFooterExtra>
                <button class="btn btn-primary" @click="confirmAddQuotation">
                    Ajouter
                    <div v-if="addQuotationInProgress" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </BlankModal>
        <blank-modal ref="rejectQuotationModal" :modal-id="quotationModalId">
            <template #modalHeader>
                <legend class="uk-legend">Réfuser un devis</legend>
            </template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <multiselect
                                v-model="selectedReason"
                                :options="rejectQuotationReasonsData.dropdownList"
                                :allow-empty="false"
                                selectedLabel=""
                                selectLabel="Cliquez pour sélectionner"
                                deselectLabel="Cliquez pour désélectionner"
                                track-by="value"
                                label="label"
                                placeholder="Liste des raison de refus de devis"
                            />
                        </div>
                    </div>
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <textarea
                                class="form-control mb-3"
                                v-model="rejectQuotationReasonsData.comment"
                                rows="5"
                                placeholder="Commentaire"
                            ></textarea>
                        </div>
                    </div>
                </fieldset>
            </template>
            <template v-slot:modalFooterExtra>
                <button class="btn btn-primary" @click="confirmRejectQuotation">
                    Confirmer
                    <div v-if="rejectQuotationInProgress" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import BlankModal from '@/components/BlankModal'
import store from '@/store'
import SimpleAccordion from '@/components/SimpleAccordion'
import Vue from 'vue'
import random from '@/helpers/random'
import { mapActions, mapState } from 'vuex'
import { rejectQuotationList } from '@/enum/rejectQuotationReasons'
import FileUpload from '@/components/FileUpload.vue'

export default {
    name: 'QuotationsList',
    components: {
        FileUpload,
        SimpleAccordion,
        BlankModal,
    },
    data: () => ({
        quotationModalId: random.htmlId(),
        selectedQuotation: [],
        rejectQuotationReasonsData: {
            dropdownList: rejectQuotationList,
            comment: '',
        },
        selectedReason: null,
        documents: [],
        addQuotationData: {
            mainPurpose: '',
            amountWithTax: 0,
            amountWoTax: 0,
            isActive: true,
            requireTaxReduceForm: false,
            dataUrl: window.location.protocol + '//' + window.location.host,
        },
    }),
    computed: {
        ...mapState('quotations', [
            'quotationList',
            'quotationListIsUpdating',
            'addQuotationInProgress',
            'acceptQuotationInProgress',
            'rejectQuotationInProgress',
            'deleteQuotationInProgress',
        ]),
        columns: function () {
            return [
                {
                    label: 'Objet',
                    field: 'mainPurpose',
                    hidden: this.shouldBeHidden('xs'),
                },
                {
                    label: 'Montant HT',
                    field: 'amountWoTax',
                    hidden: this.shouldBeHidden('sm'),
                    formatFn: Vue.filter('amountFormat'),
                },
                {
                    label: 'Montant TTC',
                    field: 'amountWithTax',
                    hidden: this.shouldBeHidden('sm'),
                    formatFn: Vue.filter('amountFormat'),
                },
                {
                    label: 'Envoyé par',
                    field: 'document.ownedBy',
                    hidden: this.shouldBeHidden('xl'),
                    formatFn: this.ownedByDisplay,
                },
                {
                    label: 'Le',
                    field: 'createdAt',
                    hidden: this.shouldBeHidden('xl'),
                    formatFn: Vue.filter('humanReadableDate'),
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    width: '1%',
                },
            ]
        },
        hiddenFields: function () {
            let hiddenFields = []
            this.columns.forEach((column) => {
                if (column.hidden) {
                    hiddenFields.push(column.field)
                }
            })

            return hiddenFields
        },
    },
    methods: {
        ...mapActions('notifications', ['addNotification']),
        ...mapActions('quotations', ['addQuotation', 'acceptQuotation', 'rejectQuotation', 'deleteQuotation']),
        shouldBeHidden: function (at) {
            const bp = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'big', 'giga']
            if (!bp.includes(at)) {
                return false
            }

            return !(bp.indexOf(at) <= bp.indexOf(this.$mq))
        },
        ownedByDisplay(ownedBy) {
            if (!ownedBy) {
                return 'legacy - unknown'
            }

            return `${ownedBy.username} - ${ownedBy.userInfos.lastName} ${ownedBy.userInfos.firstName}`
        },
        downloadDocument(identifier, originalName) {
            backendApi.downloadDocument(store.state.login.user.token, identifier, originalName)
        },
        openModalQuotationAdd() {
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.quotationModal.$el).show()
        },
        openModalQuotationReject(data) {
            this.selectedQuotation = data
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.rejectQuotationModal.$el).show()
        },
        confirmRejectQuotation() {
            if (!this.selectedReason) {
                this.addNotification({
                    status: 'warning',
                    message: 'Veuillez sélectionner la raison de réfus du devis',
                })
                return
            }

            if (this.rejectQuotationReasonsData.comment === '') {
                this.addNotification({ status: 'warning', message: 'Veuillez ajouter un commentaire' })
                return
            }

            const payload = {
                ...this.selectedQuotation,
                reason: this.selectedReason.value,
                comment: this.rejectQuotationReasonsData.comment,
            }

            this.rejectQuotation(payload).then(() => {
                this.resetRejectQuotationForm()
                this.$bootstrap.Modal.getOrCreateInstance(this.$refs.rejectQuotationModal.$el).hide()
            })
        },
        resetRejectQuotationForm() {
            this.selectedReason = null
            this.rejectQuotationReasonsData.comment = ''
        },
        confirmAddQuotation() {
            if (!this.documents[0]) {
                this.addNotification({ status: 'warning', message: 'Veuillez sélectionner le document' })
                return
            }

            if (!this.addQuotationData.mainPurpose) {
                this.addNotification({ status: 'warning', message: 'Veuillez saisir un objectif principal' })
                return
            }

            const payload = {
                ...this.addQuotationData,
                document: this.documents[0],
            }

            this.addQuotation(payload).then(() => {
                this.resetAddQuotationForm()

                this.$bootstrap.Modal.getOrCreateInstance(this.$refs.quotationModal.$el).hide()
            })
        },
        resetAddQuotationForm() {
            this.documents = []
            this.addQuotationData.mainPurpose = ''
            this.addQuotationData.amountWithTax = 0
            this.addQuotationData.amountWoTax = 0
            this.addQuotationData.isActive = true
            this.addQuotationData.requireTaxReduceForm = false
            this.addQuotationData.dataUrl = window.location.protocol + '//' + window.location.host
        },
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
