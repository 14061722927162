<template>
    <SimpleAccordion>
        <template v-slot:accordionTitle>Actions et motif de clôture</template>
        <template v-slot:accordionContent>
            <div v-if="!isClosed" class="m-3">
                <small v-if="!closedReasonIsOk" class="text-danger d-inline-block mb-2">
                    Le motif de clôture doit contenir au moins {{ closedReasonMinLength }} caractères
                </small>
                <textarea
                    :disabled="closedActionIsPending"
                    class="form-control mb-3"
                    v-model="closedReason"
                    rows="5"
                    placeholder="Motif de clôture"
                ></textarea>
                <div class="text-end">
                    <div class="btn-group">
                        <button
                            type="button"
                            class="btn btn-warning dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            :disabled="closedActionIsPending || !closedReasonIsOk"
                        >
                            Clôturer
                            <div uk-spinner="ratio:1" v-if="closedActionIsPending"></div>
                        </button>
                        <ul class="dropdown-menu bg-transparent border-0">
                            <li>
                                <button type="button" class="btn btn-danger" @click="closedAction">Confirmer</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-else class="m-3">
                <div class="mb-2">Le {{ remoteClosedAt | humanReadableDate }}. Motif :</div>
                <p class="p-2 rounded-2" style="white-space: pre-line; border: 1px dashed black">
                    {{ remoteClosedReason }}
                </p>
                <div class="text-end">
                    <div class="btn-group">
                        <button
                            type="button"
                            class="btn btn-warning dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            :disabled="unclosedActionIsPending"
                        >
                            Dé-clôturer
                            <div uk-spinner="ratio:1" v-if="unclosedActionIsPending"></div>
                        </button>
                        <ul class="dropdown-menu bg-transparent border-0">
                            <li>
                                <button type="button" class="btn btn-danger" @click="unclosedAction">Confirmer</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </SimpleAccordion>
</template>

<script>
import SimpleAccordion from '@/components/SimpleAccordion'
export default {
    name: 'ClosedActionAccordion',
    components: { SimpleAccordion },
    data: () => ({
        closedReason: '',
        closedActionIsPending: false,
        unclosedActionIsPending: false,
        closedReasonMinLength: 5,
    }),
    props: {
        closedMethod: {
            type: Function,
            required: false,
            default: () => Promise.resolve(),
        },
        unclosedMethod: {
            type: Function,
            required: false,
            default: () => Promise.resolve(),
        },
        remoteClosedAt: {
            type: String,
            required: false,
            default: () => null,
        },
        remoteClosedReason: {
            type: String,
            required: false,
            default: () => null,
        },
    },
    computed: {
        closedReasonIsOk() {
            return this.closedReason.length >= this.closedReasonMinLength
        },
        isClosed() {
            return !!this.remoteClosedReason
        },
    },
    methods: {
        closedAction() {
            this.closedActionIsPending = true
            this.closedMethod(this.closedReason)
                .then(() => {
                    this.closedReason = ''
                })
                .finally(() => {
                    this.closedActionIsPending = false
                })
        },
        unclosedAction() {
            this.unclosedActionIsPending = true
            this.unclosedMethod().finally(() => {
                this.unclosedActionIsPending = false
            })
        },
    },
}
</script>

<style scoped></style>
