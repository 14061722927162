<template>
    <div>
        <div class="m-1 mb-3">
            Ticket {{ ticket.id }} - Créé le : {{ ticket.createdAt | humanReadableDate }} - Adresse :
            {{ ticket.property.address.formattedAddress }} - Propriété : {{ ticket.property.name }}
            <span v-if="ticket.delegatedToSuperManager">- <span class="badge bg-success">Ticket délégué</span></span>
        </div>
        <ContactAccordion :contacts="ticket.property.contacts" class="mb-3" />
        <ObjectAccordion :object="ticket.mainPurpose" class="mb-3" />
        <StatusAccordion
            :status="ticket.status"
            :add-status-method="addStatusMethod"
            :remove-status-method="removeStatusMethod"
            class="mb-3"
        />
        <AttachWorkerUserAccordion
            v-if="user.userData.accountType === 'workerAccount' && user.userData.roles.includes('admin')"
            class="mb-3"
            :company-user-list="$store.state.users.list"
            :attached-users="ticket.assignedWorkerUsers"
            :update-user-selection-method="updateUserSelection"
        />
        <MissionOrdersList :mission-orders="ticket.missionOrders" class="mb-3" />
        <DocumentsViewAndLoad
            class="mb-3"
            :documents="ticket.documents"
            :add-documents-method="addDocuments"
            :save-edited-image-method="saveEditedImage"
            :edit-document-name-func="editDocumentName"
            :delete-document-func="deleteDocument"
        />
        <QuotationsList class="mb-3" />
        <InformedWorkersListOnDetail
            v-if="['agencyAccount', 'superManagerAccount'].includes(user.userData.accountType)"
            :worker-companies="ticket.informedWorkers"
            class="mb-3"
            @remove:worker="removeWorker"
        />
        <ChatAccordion
            class="mb-3"
            :chat="ticket.mainChat"
            accordion-title="Chat public (messages visibles par toute personne rattachée au ticket)"
            @message:new="recordNewMessageToMainChat"
        />
        <ClosedActionAccordion
            v-if="user.userData.accountType === 'agencyAccount'"
            class="mb-3"
            :closed-method="closeTicket"
            :unclosed-method="uncloseTicket"
            :remote-closed-at="ticket.closedAt"
            :remote-closed-reason="ticket.closedReason"
        />
    </div>
</template>

<script>
import Property from '@/components/Property'
import InformedWorkersListOnDetail from '@/components/InformedWorkersListOnDetail'
import MissionOrdersList from '@/components/MissionOrdersList'
import QuotationsList from '@/components/QuotationsList'
import Chat from '@/components/Chat'
import backendApi from '@/backend/api'
import store from '@/store'
import { mapActions, mapMutations, mapState } from 'vuex'
import ObjectAccordion from '@/components/ObjectAccordion'
import StatusAccordion from '@/components/StatusAccordion'
import ChatAccordion from '@/components/ChatAccordion'
import random from '@/helpers/random'
import ImageEditor from '@/components/ImageEditor'
import DocumentsViewAndLoad from '@/components/DocumentsViewAndLoad'
import AttachWorkerUserAccordion from '@/components/AttachWorkerUserAccordion'
import ClosedActionAccordion from '@/components/ClosedActionAccordion'
import ContactAccordion from '@/components/ContactAccordion'

export default {
    name: 'Ticket',
    components: {
        ContactAccordion,
        ClosedActionAccordion,
        AttachWorkerUserAccordion,
        DocumentsViewAndLoad,
        ChatAccordion,
        StatusAccordion,
        ObjectAccordion,
        QuotationsList,
        MissionOrdersList,
        InformedWorkersListOnDetail,
    },
    data: () => ({
        defaultProps: {
            property: Property.props.property.default,
            chat: Chat.props.chat.default,
        },
        docAddModalId: random.htmlId(),
        imgEditorModalId: random.htmlId(),
        filesUploadedToAdd: [],
        imageToEdit: ImageEditor.props.imageDataToEdit.default(),
    }),
    props: {
        ticket: {
            required: false,
            default: () => ({
                id: null,
                assignedWorkerUsers: [],
                property: Property.props.property.default(),
                missionOrders: [],
                mainChat: Chat.props.chat.default(),
                privateChats: [],
                status: [],
                quotations: [],
                documents: [],
                mainPurpose: '',
                delegatedToSuperManager: false,
                informedWorkers: [],
                closedAt: null,
                closedReason: null,
            }),
        },
    },
    computed: {
        ...mapState('login', ['user']),
    },
    methods: {
        ...mapActions('notifications', ['addNotification']),
        ...mapActions('tickets', ['updateMainChat', 'updatePrivateChat']),
        ...mapMutations('tickets', ['UPDATE_DOCUMENTS']),
        recordNewMessageToMainChat(message, newMessageResult, siteUrl) {
            backendApi
                .addMessageToTicketMainChat(store.state.login.user.token, this.ticket.id, message, siteUrl)
                .then(() => {
                    this.updateMainChat()
                    this.addNotification({
                        message: 'Message envoyé',
                        status: 'success',
                    })
                    newMessageResult(true)
                })
                .catch((error) => {
                    newMessageResult(false)
                    backendApi.notificationOnError(error).catch(() => {})
                })
        },
        recordNewMessageToPrivateChat(privateChatId, message, newMessageResult) {
            backendApi
                .addMessageToTicketPrivateChat(store.state.login.user.token, this.ticket.id, privateChatId, message)
                .then(() => {
                    this.updatePrivateChat(privateChatId)
                    this.addNotification({
                        message: 'Message envoyé',
                        status: 'success',
                    })
                    newMessageResult(true)
                })
                .catch((error) => {
                    newMessageResult(false)
                    backendApi.notificationOnError(error).catch(() => {})
                })
        },
        addDocuments(filesUploadedToAdd) {
            return backendApi
                .addDocumentsToTicket(store.state.login.user.token, this.ticket.id, filesUploadedToAdd)
                .then((data) => {
                    this.UPDATE_DOCUMENTS(data.documents)
                })
                .catch(backendApi.notificationOnError)
        },
        saveEditedImage(file) {
            return backendApi
                .uploadFile(store.state.login.user.token, file)
                .then((data) => backendApi.addDocumentsToTicket(store.state.login.user.token, this.ticket.id, [data]))
                .then((data) => {
                    this.ticket.documents = data.documents
                })
        },
        addStatusMethod(statusId) {
            return backendApi.addStatusToTicket(store.state.login.user.token, this.ticket.id, statusId).then((data) => {
                this.ticket.status = data.status
            })
        },
        removeStatusMethod(statusId) {
            return backendApi
                .removeStatusToTicket(store.state.login.user.token, this.ticket.id, statusId)
                .then((data) => {
                    this.ticket.status = data.status
                })
        },
        removeWorker() {},
        updateUserSelection(usersSelected) {
            return backendApi
                .updateTicketAssignedWorkerUsers(store.state.login.user.token, this.ticket.id, usersSelected)
                .then((data) => {
                    this.addNotification({ status: 'success', message: 'Utilisateurs rattachés modifié' })
                    return (this.ticket.assignedWorkerUsers = data.assignedWorkerUsers)
                })
                .catch(backendApi.notificationOnError)
        },
        closeTicket(reason) {
            return backendApi
                .closeTicket(store.state.login.user.token, this.ticket.id, reason)
                .then(() => {
                    this.addNotification({ status: 'success', message: 'Clôture du ticket effectué' })
                    this.$router.push({ name: 'tickets' })
                })
                .catch(backendApi.notificationOnError)
        },
        uncloseTicket() {
            return backendApi
                .uncloseTicket(store.state.login.user.token, this.ticket.id)
                .then((data) => {
                    this.$store.dispatch('tickets/setCurrentTicket', data.id)
                    this.addNotification({ status: 'success', message: 'Dé-clôture du ticket effectué' })
                })
                .catch(backendApi.notificationOnError)
        },
        editDocumentName(document, newName) {
            console.log(document, newName)
            return backendApi
                .renameTicketDocument(store.state.login.user.token, this.ticket.id, document.id, newName)
                .then((data) => {
                    this.UPDATE_DOCUMENTS(data.documents)
                })
                .catch(backendApi.notificationOnError)
        },
        deleteDocument(document) {
            return backendApi
                .deleteTicketDocument(store.state.login.user.token, this.ticket.id, document.id)
                .then((data) => {
                    this.UPDATE_DOCUMENTS(data.documents)
                })
                .catch(backendApi.notificationOnError)
        },
    },
    mounted() {
        store.dispatch('quotations/fetchQuotationsFromTicket')
    },
}
</script>

<style scoped></style>
