import backendApi from '../../backend/api'
import router from '../../router'

const blankUser = () => ({
    token: null,
    userData: {
        roles: [],
        accountType: null,
    },
})

const localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : blankUser()

localStorage.setItem('user', JSON.stringify(localUser))

export default {
    namespaced: true,
    state: () => ({
        user: Object.assign({}, localUser),
        loginError: null,
        loginIsInProgress: false,
    }),
    mutations: {
        UPDATE_USER(store, payload) {
            store.user = payload
            localStorage.setItem('user', JSON.stringify(store.user))
        },
        UPDATE_USER_DATA(store, payload) {
            store.user.userData = payload
            localStorage.setItem('user', JSON.stringify(store.user))
        },
        UPDATE_LOGIN_ERROR(store, payload) {
            store.loginError = payload
        },
    },
    actions: {
        updateUser(context, payload) {
            context.commit('UPDATE_USER', payload)
        },
        login(context, payload) {
            context.state.loginIsInProgress = true
            let user = Object.assign({}, blankUser)
            backendApi
                .loginUser(payload.username, payload.password)
                .then((payload) => {
                    user.token = payload.token

                    return payload
                })
                .then((payload) => backendApi.getUserInfo(payload.token))
                .then((payload) => {
                    user.userData = payload
                    context.commit('UPDATE_USER', user)
                    context.dispatch('dashboard/getDashboardData', null, { root: true })
                    context.dispatch('dashboard/getDashboardConfigData', null, { root: true })
                    window.scroll(0, 0)
                })
                .catch(() => {})
                .finally(() => {
                    context.state.loginIsInProgress = false
                })
        },
        loginUpdate(context) {
            backendApi
                .loginUpdate(context.state.user.token)
                .then((payload) => {
                    let user = context.state.user
                    user.token = payload.token

                    context.commit('UPDATE_USER', user)
                    context.dispatch('dashboard/getDashboardData', null, { root: true })
                    context.dispatch('dashboard/getDashboardConfigData', null, { root: true })
                })
                .catch((error) => {
                    if (error?.response?.status !== undefined) {
                        if (error?.response?.status === 401) {
                            context.dispatch('logout')
                        }
                    }
                })
        },
        updateUserInfos(context) {
            backendApi.getUserInfo(context.state.user.token).then((payload) => {
                context.commit('UPDATE_USER_DATA', payload)
            })
        },
        logout(context) {
            context.commit('UPDATE_USER', blankUser())
            router.push({ name: 'root' })
        },
    },
    getters: {
        isWorker(state) {
            if (!state.user.userData.accountType) return false
            return state.user.userData.accountType === 'workerAccount'
        },
        isAgency(state) {
            if (!state.user.userData.accountType) return false
            return state.user.userData.accountType === 'agencyAccount'
        },
        isSuperManager(state) {
            if (!state.user.userData.accountType) return false
            return state.user.userData.accountType === 'superManagerAccount'
        },
    },
}
