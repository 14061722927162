import axios from 'axios'
import download from '@/helpers/download'
const qs = require('qs')
const FormData = require('form-data')
let store = () => import('@/store')

;('use strict')

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_MODEL_API_ROOT,
    headers: {
        common: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    },
    timeout: process.env.NODE_ENV === 'production' ? 1000 * 30 : 1000 * 30,
})

class backendApi {
    constructor(axios, store) {
        this.axios = axios
        this.store = undefined
        store().then((module) => {
            this.store = module.default
        })
    }

    getUserInfo(token) {
        return this.axios
            .get('/user', { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => response.data)
    }

    loginUser(username, password) {
        return this.axios
            .post('/login', { username, password })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    loginUpdate(token) {
        return this.axios
            .get('/login', { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => response.data)
    }

    getProperties(token) {
        return this.axios
            .get('/properties', { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => response.data)
    }

    getProperty(token, propertyId) {
        return this.axios
            .get(`/property/${propertyId}`, { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    updateProperty(token, propertyId, property) {
        return this.axios
            .post(`/property/${propertyId}`, property, { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    createProperty(token, property) {
        return this.axios
            .post(`/property`, property, { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    getTickets(token, serverParams) {
        const params = qs.stringify(serverParams)
        return this.axios
            .get('/tickets?' + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getTicket(token, ticketId) {
        return this.axios
            .get('/ticket/' + ticketId, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    createTicket(token, ticketInfo) {
        return this.axios
            .post(`/ticket`, ticketInfo, { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    getTicketMainChat(token, ticketId) {
        return this.axios
            .get(`/ticket/${ticketId}/main-chat`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    addMessageToTicketMainChat(token, ticketId, message, siteUrl) {
        return this.axios
            .post(
                `/ticket/${ticketId}/main-chat/new-message`,
                { message, siteUrl },
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    getTicketPrivateChat(token, ticketId, privateChatId) {
        return this.axios
            .get(`/ticket/${ticketId}/private-chat/${privateChatId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    addMessageToTicketPrivateChat(token, ticketId, privateChatId, message) {
        return this.axios
            .post(
                `/ticket/${ticketId}/private-chat/${privateChatId}/new-message`,
                { message },
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    addDocumentsToTicket(token, ticketId, documents) {
        return this.axios
            .post(
                `/ticket/${ticketId}/add-documents`,
                { documents },
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    renameTicketDocument(token, ticketId, documentId, name) {
        return this.axios
            .post(
                `/ticket/${ticketId}/document/${documentId}/rename`,
                { name },
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    deleteTicketDocument(token, ticketId, documentId) {
        return this.axios
            .delete(`/ticket/${ticketId}/document/${documentId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    detachCompanyFunc(token, companyRegistrationNumber) {
        return this.axios
            .patch(
                `/worker-account/detach/${companyRegistrationNumber}`,
                {},
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    updateTicketAssignedWorkerUsers(token, ticketId, usersSelected) {
        return this.axios
            .patch(`/ticket/${ticketId}/assigned-worker-users`, usersSelected, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    closeTicket(token, ticketId, reason) {
        return this.axios
            .post(
                `/ticket/${ticketId}/close`,
                { reason },
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    uncloseTicket(token, ticketId) {
        return this.axios
            .delete(`/ticket/${ticketId}/close`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    rejectQuotation(token, data) {
        return this.axios
            .post(`/reject-quotation`, data, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    addQuotation(token, data) {
        return this.axios
            .post(`/add-quotation`, data, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    acceptQuotation(token, data) {
        return this.axios
            .post(`/accept-quotation`, data, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    deleteQuotation(token, quotationId) {
        return this.axios
            .delete(`/delete-quotation/${quotationId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    getMissionOrders(token, serverParams) {
        const params = qs.stringify(serverParams)
        return this.axios
            .get('/mission-orders?' + params, {
                // params,
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getMissionOrder(token, missionOrderId) {
        return this.axios
            .get(`/mission-order/${missionOrderId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    createMissionOrder(token, missionOrderInfo) {
        return this.axios
            .post(`/mission-order`, missionOrderInfo, { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    getMissionOrderMainChat(token, missionOrderId) {
        return this.axios
            .get(`/mission-order/${missionOrderId}/main-chat`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    addMessageToMissionOrderMainChat(token, missionOrderId, message, siteUrl) {
        return this.axios
            .post(
                `/mission-order/${missionOrderId}/main-chat/new-message`,
                { message, siteUrl },
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    getMissionOrderPrivateChat(token, missionOrderId, privateChatId) {
        return this.axios
            .get(`/mission-order/${missionOrderId}/private-chat/${privateChatId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    addMessageToMissionOrderPrivateChat(token, missionOrderId, privateChatId, message) {
        return this.axios
            .post(
                `/mission-order/${missionOrderId}/private-chat/${privateChatId}/new-message`,
                { message },
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    addDocumentsToMissionOrder(token, missionOrderId, documents) {
        return this.axios
            .post(
                `/mission-order/${missionOrderId}/add-documents`,
                { documents },
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    renameMissionOrderDocument(token, missionOrderId, documentId, name) {
        return this.axios
            .post(
                `/mission-order/${missionOrderId}/document/${documentId}/rename`,
                { name },
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    deleteMissionOrderDocument(token, missionOrderId, documentId) {
        return this.axios
            .delete(`/mission-order/${missionOrderId}/document/${documentId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    closeMissionOrder(token, missionOrderId, reason) {
        return this.axios
            .post(
                `/mission-order/${missionOrderId}/close`,
                { reason },
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    uncloseMissionOrder(token, missionOrderId) {
        return this.axios
            .delete(`/mission-order/${missionOrderId}/close`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getWorkersAccount(token, serverParams) {
        const params = qs.stringify(serverParams)
        return this.axios
            .get('/worker-accounts?' + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getBuildingTradesList(token) {
        return this.axios
            .get(`/building-trades`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getCompaniesByBuildingTrades(buildingTrades) {
        const params = qs.stringify({ buildingTrades })
        return this.axios
            .get(`/companies-by-building-trades?` + params, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    getRegistrationNumbersByCompanies(companies) {
        const params = qs.stringify({ companies })
        return this.axios
            .get(`/registration-numbers-by-companies?` + params, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    addWorker(token, workerPayload) {
        return this.axios
            .post(`/worker`, workerPayload, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getDashboardData(token) {
        return this.axios
            .get(`/dashboard`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getDashboardConfigData(token) {
        return this.axios
            .get(`/dashboard-config`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    updateDashboardConfigData(token, dashboardConfigData) {
        return this.axios
            .patch(`/dashboard-config`, dashboardConfigData, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getStatus(token) {
        return this.axios
            .get(`/status`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    editStatus(token, status) {
        return this.axios
            .post(`/status/${status.id}`, status, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    deleteStatus(token, statusId) {
        return this.axios
            .delete(`/status/${statusId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    createStatus(token, newStatus) {
        return this.axios
            .post(`/status`, newStatus, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    addStatusToTicket(token, ticketId, statusId) {
        return this.axios
            .post(
                `/ticket/${ticketId}/status/${statusId}`,
                {},
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    removeStatusToTicket(token, ticketId, statusId) {
        return this.axios
            .delete(`/ticket/${ticketId}/status/${statusId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    addStatusToMissionOrder(token, missionOrderId, statusId) {
        return this.axios
            .post(
                `/mission-order/${missionOrderId}/status/${statusId}`,
                {},
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    removeStatusToMissionOrder(token, missionOrderId, statusId) {
        return this.axios
            .delete(`/mission-order/${missionOrderId}/status/${statusId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    updateMissionOrderAssignedWorkerUsers(token, missionOrderId, usersSelected) {
        return this.axios
            .patch(`/mission-order/${missionOrderId}/assigned-worker-users`, usersSelected, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    downloadDocument(
        token,
        identifier,
        originalName,
        autoDownload = true,
        downloadProgressCallback = () => {},
        abortController = new AbortController(),
    ) {
        return this.axios
            .get('/download/' + identifier, {
                headers: { Authorization: 'Bearer ' + token },
                responseType: 'blob',
                onDownloadProgress: downloadProgressCallback,
                signal: abortController.signal,
            })
            .then(async (response) => {
                let blob = new Blob([response.data])
                if (autoDownload) {
                    const downloadResult = await download.downloadBlob(blob, originalName)
                    console.log(downloadResult)
                    if (this.store) {
                        this.store.dispatch('notifications/addNotification', {
                            status: 'success',
                            message: 'Fichier téléchargé dans votre dossier de téléchargement',
                        })
                    }
                }
                return new File([blob], originalName, { type: response.headers['content-type'] })
            })
            .catch(this.notificationOnError)
    }

    uploadFile(token, file, uploadProgressCallback = () => {}, abortController = new AbortController()) {
        const form = new FormData()
        form.append('file', file, file.name)

        return this.axios
            .post('/upload', form, {
                timeout: 0,
                onUploadProgress: uploadProgressCallback,
                signal: abortController.signal,
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            .then((response) => response.data)
    }

    updateSelfInfos(token, userInfos) {
        return this.axios
            .patch(`/user`, userInfos, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getUsersList(token) {
        return this.axios
            .get(`/users`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    createUser(token, userData) {
        return this.axios
            .post(`/user`, userData, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    updateUser(token, userData) {
        return this.axios
            .patch(`/user/${userData.id}`, userData, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getMessageReadHistory(token) {
        return this.axios
            .get(`/message-read-history`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getMessageReadHistoryFrom(token, from) {
        const params = qs.stringify({ from })
        return this.axios
            .get(`/message-read-history?` + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    deleteMessageReadHistory(token, messageReadHistoryId) {
        return this.axios
            .delete(`/message-read-history/${messageReadHistoryId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    notificationOnError = (error) => {
        let message = 'error unknown'

        if (error?.message) {
            message = error?.message
        }

        if (error?.response?.data?.message) {
            message = error?.response?.data?.message
        }

        if (error?.response?.data?.exception?.message) {
            message = error?.response?.data?.exception?.message
        }

        error.message = message

        if (this.store) {
            this.store.dispatch('notifications/addNotification', { message, status: 'danger' })
        }

        throw error
    }

    handleErrorWithoutNotification(error) {
        error.message = error.response.data.message
        throw error
    }

    //------------------ reset password methods ------------------
    askResetPassword(userdata, siteUrl) {
        return this.axios
            .post(`user/reset-password`, { userdata, siteUrl })
            .then((response) => response.data)
            .catch(this.handleErrorWithoutNotification)
    }

    verificationCode(userdata) {
        return this.axios
            .post(`user/check-code`, userdata)
            .then((response) => response.data)
            .catch(this.handleErrorWithoutNotification)
    }

    validateResetPassword(userdata) {
        return this.axios
            .patch(`user/update-password`, userdata)
            .then((response) => response.data)
            .catch(this.handleErrorWithoutNotification)
    }

    //------------------ video formation methods ------------------
    getVideoFormation(token, serverParams) {
        const params = qs.stringify(serverParams)
        return this.axios
            .get(`/videos-formations?${params}`, { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => response.data)
    }

    getQuotationsAndInvoices(token, missionOrderId) {
        return this.axios
            .get(`/mission-order-quotations-invoices/${missionOrderId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    createInvoice(token, data) {
        return this.axios
            .post(`/create-invoice`, data, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    deleteInvoice(token, invoiceId) {
        return this.axios
            .delete(`/delete-invoice/${invoiceId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
}

export default new backendApi(axiosInstance, store)
