import backendApi from "@/backend/api";

/**
 * @typedef {Object} Quotation
 * @property {number} id
 * @property {string} type
 * @property {string} mainPurpose
 * @property {number} amountWoTax
 * @property {number} amountWithTax
 * @property {string} ownedBy
 * @property {Date} createdAt
 * @property {Date || null} acceptedAt
 * @property {Invoice[]} children
 */

/**
 * @typedef {Object} Invoice
 * @property {number} id
 * @property {string} type
 * @property {string} mainPurpose
 * @property {number} amountWoTax
 * @property {number} amountWithTax
 * @property {string} ownedBy
 * @property {Date} createdAt
 */

/**
 * @typedef {Object} transformedData
 * @property {Quotation[]} quotations
 * @property {Invoice[]} invoices
 */

export default {
    namespaced: true,
    state: () => ({
        list: [],
        listIsUpdating : false,
        createQuotationInProgress: false,
        deleteQuotationInProgress: false,
        acceptQuotationInProgress: false,
        rejectQuotationInProgress: false,
        createInvoiceInProgress: false,
        deleteInvoiceInProgress: false,
    }),
    mutations: {
        UPDATE_QUOTATIONS_AND_INVOICES_LIST(state, payload) {
            state.list = payload
        }
    },
    actions: {
        fetchQuotationsAndInvoices(context) {
            return backendApi
                .getQuotationsAndInvoices(
                    context.rootState.login.user.token,
                    context.rootState.missionOrders.currentMissionOrder.id
                )
                .then((data) => {
                    const transformedData = data.flatMap(row => {
                        const quotations = row.quotations.map(quotation => ({
                            id: quotation.id,
                            type: quotation.type,
                            mainPurpose: quotation.mainPurpose,
                            amountWoTax: parseFloat(quotation.amountWoTax),
                            amountWithTax: parseFloat(quotation.amountWithTax),
                            document: quotation.document,
                            createdAt: quotation.createdAt.date,
                            acceptedAt: quotation.acceptedAt ? quotation.acceptedAt.date : null,
                            children: quotation.invoices.map(invoice => ({
                                id: invoice.id,
                                type: invoice.type,
                                mainPurpose: invoice.mainPurpose,
                                amountWoTax: parseFloat(invoice.amountWoTax),
                                amountWithTax: parseFloat(invoice.amountWithTax),
                                document: invoice.document,
                                createdAt: invoice.createdAt.date
                            }))
                        }))

                        const invoices = row.invoices.map(invoice => ({
                            id: invoice.id,
                            type: invoice.type,
                            mainPurpose: invoice.mainPurpose,
                            amountWoTax: parseFloat(invoice.amountWoTax),
                            amountWithTax: parseFloat(invoice.amountWithTax),
                            document: invoice.document,
                            createdAt: invoice.createdAt.date,
                            children: []
                        }))

                        return [...quotations, ...invoices]
                    })

                    context.commit('UPDATE_QUOTATIONS_AND_INVOICES_LIST', transformedData)
                })
        },
        createQuotation(context, payload) {
            const payloadData = {
                payload: payload,
                ticket: context.rootState.tickets.currentTicket,
                missionOrder: context.rootState.missionOrders.currentMissionOrder,
            }
            context.state.listIsUpdating = true
            context.state.createQuotationInProgress = true
            return backendApi
                .addQuotation(context.rootState.login.user.token, payloadData)
                .then(() => {
                    context.dispatch(
                        'notifications/addNotification',
                        {
                            status: 'success',
                            message: 'Devis ajouté avec success',
                        },
                        { root: true },
                    )
                    context.dispatch('fetchQuotationsAndInvoices')
                })
                .finally(() => {
                    context.state.listIsUpdating = false
                    context.state.createQuotationInProgress = false
                })
        },
        deleteQuotation(context, quotation) {
            context.state.listIsUpdating = true
            context.state.deleteQuotationInProgress = true
            return backendApi
                .deleteQuotation(context.rootState.login.user.token, quotation.id)
                .then(() => {
                    context.dispatch(
                        'notifications/addNotification',
                        {
                            status: 'success',
                            message: 'Devis supprimé',
                        },
                        { root: true },
                    )
                    context.dispatch('fetchQuotationsAndInvoices')
                })
                .finally(() => {
                    context.state.listIsUpdating = false
                    context.state.deleteQuotationInProgress = false
                })
        },
        rejectQuotation(context, payload) {
            context.state.listIsUpdating = true
            context.state.rejectQuotationInProgress = true
            return backendApi
                .rejectQuotation(context.rootState.login.user.token, payload)
                .then(() => {
                    context.dispatch(
                        'notifications/addNotification',
                        {
                            status: 'success',
                            message: 'Devis rejeté',
                        },
                        { root: true },
                    )
                    context.dispatch('fetchQuotationsAndInvoices')
                })
                .finally(() => {
                    context.state.listIsUpdating = false
                    context.state.rejectQuotationInProgress = false
                })
        },
        acceptQuotation(context, quotation) {
            const payloadData = {
                ...quotation,
                dataUrl: window.location.protocol + '//' + window.location.host,
            }
            context.state.listIsUpdating = true
            context.state.acceptQuotationInProgress = true
            return backendApi
                .acceptQuotation(context.rootState.login.user.token, payloadData)
                .then(() => {
                    context.dispatch(
                        'notifications/addNotification',
                        {
                            status: 'success',
                            message: 'Devis validé',
                        },
                        { root: true },
                    )
                    context.dispatch('fetchQuotationsAndInvoices')
                })
                .finally(() => {
                    context.state.listIsUpdating = false
                    context.state.acceptQuotationInProgress = false
                })
        },
        createInvoice(context, payloadData) {
            const payload = {
                ...payloadData,
                missionOrderId: context.rootState.missionOrders.currentMissionOrder.id
            }
            context.state.listIsUpdating = true
            context.state.createInvoiceInProgress = true
            return backendApi
                .createInvoice(context.rootState.login.user.token, payload)
                .then(() => {
                    context.dispatch(
                        'notifications/addNotification',
                        {
                            status: 'success',
                            message: 'Facture ajoutée avec success',
                        },
                        { root: true },
                    )
                    context.dispatch('fetchQuotationsAndInvoices')
                })
                .finally(() => {
                    context.state.listIsUpdating = false
                    context.state.createInvoiceInProgress = false
                })
        },
        deleteInvoice(context, invoice) {
            context.state.listIsUpdating = true
            context.state.deleteInvoiceInProgress = true
            return backendApi
                .deleteInvoice(context.rootState.login.user.token, invoice.id)
                .then(() => {
                    context.dispatch(
                        'notifications/addNotification',
                        {
                            status: 'success',
                            message: 'Facture supprimée',
                        },
                        { root: true },
                    )
                    context.dispatch('fetchQuotationsAndInvoices')
                })
                .finally(() => {
                    context.state.listIsUpdating = false
                    context.state.deleteInvoiceInProgress = false
                })
        },
    },
    getters: {}
}
